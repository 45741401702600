<template>
    <ContentWrapper>
        <breadcrumb/>
        <div class="container">
            <grow v-for="grow in grows" :grow="grow" :key="grow.label"></grow>
        </div>
    </ContentWrapper>
</template>
<style lang="scss">

    .webcam-image.inactive {
        opacity: 0.4;
        filter: grayscale(100%);
    }

    .card-image .card-body div {
        overflow: hidden;
    }

    .badge-yellow {
        color: #292929;
        background-color: #ffdf32;
    }
</style>
<script>


    import Grow from "../components/Grow";
    export default {
        components: {Grow},
        data() {
            return {
                loading: true,
                grows: [],
                stages: [
                    'maintenance',
                    'cleaning',
                    'vegetative',
                    'flowering',
                    'harvest',
                    'drying_and_curing',
                    'de_stemming_and_trimming',
                    'release'
                ]
            }
        },
      beforeCreate() {
        this.$router.push({name: 'Growing:Rooms'});
      },
      created() {
            this.$api.get('growing/grows?scope=active').then(result => {
                this.loading = false;
                this.grows = result.data;
            });
        },
        methods: {
            isHarvested(current_stage) {
                  return current_stage === 'drying_and_curing' || current_stage === 'de_stemming_and_trimming' || current_stage === 'release';
            },
            getNextStage(current_stage) {
                for (let i = 0; i < this.stages.length - 1; i++) {
                    if (this.stages[i] === current_stage && this.stages[i + 1]) {
                        return this.stages[i + 1];
                    }
                }

                return undefined;
            },
        }
    }
</script>
