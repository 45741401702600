<template>
    <div v-if="grow.status.current.name !== 'construction'" class="card card-grow-list-item  animated-fast fadeInRight" :class="{'card-gray': isHarvested(grow.status.current.name), 'card-default': !isHarvested(grow.current_status.name)}" >
        <div class="card-header">
            Grow #{{grow.label}}

            <span class="float-right">
                        {{ grow.location_name}} | {{ grow.room_name }}
                    </span>
        </div>

        <div class="card-body p-0 border-bottom-0" style="background-image: none;">
            <b-row>
                <b-col xl="4" md="6" class="pr-0">
                    <img alt="webcam" class="w-100 webcam-image" v-if="grow.webcam_image" :src="grow.webcam_image" :class="{inactive: isHarvested(grow.status.current.name)}"/>
                </b-col>
                <b-col xl="8" md="6" v-if="grow.status.current">
                    <b-row style="padding-right: 15px;">
                        <b-col lg="12" xl="4" class="h-100 p-0 status-block" style="opacity: 0.5">
                            <template v-if="grow.status.last">
                                <div class="p-2  bg-gray"><i class="mr-2" :class="$t('growing.component.grow.statuses.' + grow.status.last.name + '.icon')"></i> {{ $t('growing.component.grow.status_last') }} <span
                                        class="float-right"><i class="fad fa-arrow-right"></i></span></div>

                                <div class="p-2">
                                    <div class="text-center">
                                        <span class="badge badge-primary  mb-1">{{ $t('growing.component.grow.statuses.' + grow.status.last.name + '.title') }}</span>
                                    </div>
                                    <i class="fad fa-calendar-check mr-2"></i> {{ grow.status.last.starts_at | moment('calendar')}}
                                </div>
                            </template>
                            <template v-else>
                                <div class="p-2  bg-gray"><i class="mr-2"></i> {{ $t('growing.component.grow.status_last') }} <span
                                        class="float-right"><i class="fad fa-arrow-right"></i></span></div>

                                <div class="p-2">
                                    <div class="text-center">
                                        <span class="badge badge-secondary  mb-1"> - </span>
                                    </div>
                                    <i class="fad fa-calendar-check mr-2"></i> -
                                </div>
                            </template>
                        </b-col>
                        <b-col lg="12" xl="4" class="h-100 p-0 status-block">

                            <div class="p-2  text-white bg-gray"><i class="mr-2" :class="$t('growing.component.grow.statuses.' + grow.status.current.name + '.icon')"></i> {{ $t('growing.component.grow.status_current') }} <span
                                    class="float-right"><i class="fad fa-arrow-right"></i></span></div>

                            <div class="p-2">
                                <div class="text-center">
                                    <span class="badge badge-primary mb-1">{{ $t('growing.component.grow.statuses.' + grow.status.current.name + '.title') }}</span>
                                </div>
                                <i class="fad fa-calendar-day mr-2"></i> {{ grow.status.current.starts_at | moment('calendar')}}
                            </div>
                        </b-col>
                        <b-col lg="12" xl="4" class="h-100 p-0 status-block" style="opacity: 0.5" v-if="grow.status.next">

                            <div class="p-2  bg-gray"><i class="mr-2" :class="$t('growing.component.grow.statuses.' + grow.status.next.name + '.icon')"></i> {{ $t('growing.component.grow.status_next') }} <span class="float-right"><i
                                    class="fad fa-arrow-right"></i></span></div>
                            <div class="p-2">
                                <div class="text-center">
                                    <span class="badge badge-primary mb-1">{{ $t('growing.component.grow.statuses.' + grow.status.next.name + '.title') }}</span>
                                </div>
                                <i class="fad fa-calendar-day mr-2"></i> {{ grow.status.next.starts_at | moment('calendar')}}
                            </div>
                        </b-col>
                        <template v-else>
                            <b-col lg="12" xl="4" class="h-100 p-0 status-block" style="opacity: 0.5" v-if="getNextStage(grow.status.current.name)">

                                <div class="p-2  bg-gray"><i class="mr-2" :class="$t('growing.component.grow.statuses.' + getNextStage(grow.status.current.name) + '.icon')"></i> {{ $t('growing.component.grow.status_next') }} <span
                                        class="float-right"><i class="fad fa-arrow-right"></i></span></div>
                                <div class="p-2">
                                    <div class="text-center">
                                        <span class="badge badge-primary mb-1">{{ $t('growing.component.grow.statuses.' + getNextStage(grow.status.current.name) + '.title') }}</span>
                                    </div>
                                    <i class="fad fa-calendar-day mr-2"></i> -
                                </div>
                            </b-col>
                        </template>
                    </b-row>
                    <hr/>

                    <div class="p-2 d-sm-none d-none d-md-none d-xl-block">
                        {{ $t('growing.component.grow.statuses.' + grow.status.current.name + '.description') }}
                    </div>
                </b-col>
            </b-row>
            <div class="p-3 d-lg-block d-xl-none" >
                {{ $t('growing.component.grow.statuses.' + grow.status.current.name + '.description') }}
            </div>
        </div>

        <div class="card-footer bg-secondary text-white">
            <b-row>
                <b-col lg="0" xl="4">

                </b-col>
                <b-col lg="12" xl="8">
                    <i class="fad fa-container-storage mr-2"></i> {{ $t('growing.component.grow.strain') }}: <span class="badge badge-primary">{{ grow.strain_name }}</span> | <i class="fad fa-seedling"></i> {{ $t('growing.component.grow.owned_plants') }}: <span class="badge badge-primary">{{ grow.plants_owned}}</span>
                </b-col>
            </b-row>
        </div>

    </div>

</template>
<style lang="scss">

    .webcam-image.inactive {
        opacity: 0.4;
        filter: grayscale(100%);
    }

    .card-image .card-body div {
        overflow: hidden;
    }

    .badge-yellow {
        color: #292929;
        background-color: #ffdf32;
    }
</style>
<script>

    export default {
        props: ['grow'],
        data() {
            return {
                stages: [
                    'maintenance',
                    'cleaning',
                    'vegetative',
                    'flowering',
                    'harvest',
                    'drying_and_curing',
                    'de_stemming_and_trimming',
                    'release'
                ]
            }
        },
        methods: {
            isHarvested(current_stage) {
                return current_stage === 'drying_and_curing' || current_stage === 'de_stemming_and_trimming' || current_stage === 'release';
            },
            getNextStage(current_stage) {
                for (let i = 0; i < this.stages.length - 1; i++) {
                    if (this.stages[i] === current_stage && this.stages[i + 1]) {
                        return this.stages[i + 1];
                    }
                }

                return undefined;
            },
        }
    }
</script>
